<template>
  <div class="text-left bg-lightgray py-4 flex justify-between lg:px-10 px-5">
    <h2 class="text-secondary text-2xl font-medium my-auto">Roles</h2>
    <button
      class="
        rounded
        border border-primary
        bg-primary
        py-2
        px-4
        text-white
        font-medium
        flex
      "
      @click="onOpenDrawerRole({ option: 'create' })"
    >
      Crear rol
    </button>
  </div>

  <div class="grid relative w-full lg:px-10 px-5">
    <div class="overflow-x-scroll">
      <table class="w-full">
        <thead class="">
          <tr>
            <th class="px-6 py-2 text-sm text-gray-500 text-left pl-0">
              Nombre
            </th>
            <th
              v-for="module in $modules"
              :key="module"
              class="px-6 py-2 text-sm text-gray-500"
            >
              {{ module }}
            </th>
          </tr>
        </thead>
        <tbody class="divide-y">
          <template v-for="(role, roleInd) in getRoles">
            <tr v-if="role.id" :key="roleInd" class="whitespace-nowrap">
              <td
                class="
                  text-left text-secondary
                  font-light
                  px-6
                  py-4
                  text-sm
                  pl-0
                "
              >
                {{ role.name }}
              </td>
              <td
                v-for="(module, moduleKey, moduleInd) in $modules"
                :key="module"
                class="text-center text-secondary font-light px-6 py-4"
              >
                <div class="flex justify-center">
                  <div class="text-sm mr-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1559:10510)">
                        <path
                          d="M12.1434 -0.0390625H3.8577C3.22734 -0.0390625 2.71484 0.473437 2.71484 1.10379V14.8181C2.71484 15.4484 3.22734 15.9609 3.8577 15.9609H12.1434C12.7738 15.9609 13.2863 15.4484 13.2863 14.8181V1.10379C13.2863 0.473437 12.7738 -0.0390625 12.1434 -0.0390625ZM12.0006 14.6752H4.00056V1.24665H12.0006V14.6752ZM7.28627 12.8538C7.28627 13.0432 7.36153 13.2249 7.49548 13.3589C7.62944 13.4928 7.81112 13.5681 8.00056 13.5681C8.19 13.5681 8.37168 13.4928 8.50563 13.3589C8.63959 13.2249 8.71484 13.0432 8.71484 12.8538C8.71484 12.6644 8.63959 12.4827 8.50563 12.3487C8.37168 12.2148 8.19 12.1395 8.00056 12.1395C7.81112 12.1395 7.62944 12.2148 7.49548 12.3487C7.36153 12.4827 7.28627 12.6644 7.28627 12.8538Z"
                          :fill="
                            role.permissions[moduleInd]?.hasMobilePermissions
                              ? '#0057FF'
                              : '#D0D0DA'
                          "
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1559:10510">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div
                    class="text-sm mr-3"
                    :class="
                      role.permissions[moduleInd]?.hasWebPermissions
                        ? 'font-bold'
                        : ''
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M15.9444 13.9516L14.8641 10.1462V1.86049C14.8641 1.54442 14.6087 1.28906 14.2927 1.28906H1.72122C1.40515 1.28906 1.1498 1.54442 1.1498 1.86049V10.1462L0.0694396 13.9516C-0.064489 14.3248 0.210511 14.7176 0.606939 14.7176H15.4069C15.8034 14.7176 16.0784 14.3248 15.9444 13.9516ZM2.43551 2.57478H13.5784V9.62835H2.43551V2.57478ZM6.5123 13.4319L6.65694 12.7712H9.34087L9.48551 13.4319H6.5123ZM10.5123 13.4319L10.1712 11.8837C10.1569 11.8176 10.098 11.7712 10.0319 11.7712H5.96765C5.8998 11.7712 5.84265 11.8176 5.82837 11.8837L5.4873 13.4319H1.55337L2.30872 10.7712H13.7052L14.4605 13.4319H10.5123Z"
                        :fill="
                          role.permissions[moduleInd]?.hasWebPermissions
                            ? '#0057FF'
                            : '#D0D0DA'
                        "
                      />
                    </svg>
                  </div>
                </div>
              </td>
              <td class="px-6 py-4 pr-0">
                <div class="flex md:justify-end">
                  <a
                    class="
                      text-primary
                      font-medium
                      cursor-pointer
                      text-right
                      w-full
                      hover:bg-blue-50
                      px-2
                    "
                    @click="onOpenDrawerRole({ action: 'update', role: role })"
                  >
                    <p>Editar</p>
                  </a>
                  <button class="ml-4 hover:bg-red-50 px-2">
                    <!-- <span class="text-red-500 font-medium">Eliminar</span> -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M5.28544 2.14118H5.14258C5.22115 2.14118 5.28544 2.0769 5.28544 1.99833V2.14118H10.714V1.99833C10.714 2.0769 10.7783 2.14118 10.8569 2.14118H10.714V3.4269H11.9997V1.99833C11.9997 1.36797 11.4872 0.855469 10.8569 0.855469H5.14258C4.51222 0.855469 3.99972 1.36797 3.99972 1.99833V3.4269H5.28544V2.14118ZM14.2854 3.4269H1.71401C1.39794 3.4269 1.14258 3.68225 1.14258 3.99833V4.56976C1.14258 4.64833 1.20686 4.71261 1.28544 4.71261H2.36401L2.80508 14.0519C2.83365 14.6608 3.33722 15.1412 3.94615 15.1412H12.0533C12.664 15.1412 13.1658 14.6626 13.1944 14.0519L13.6354 4.71261H14.714C14.7926 4.71261 14.8569 4.64833 14.8569 4.56976V3.99833C14.8569 3.68225 14.6015 3.4269 14.2854 3.4269ZM11.9158 13.8555H4.08365L3.65151 4.71261H12.3479L11.9158 13.8555Z"
                        fill="#E00F57"
                      />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>

  <EndDrawer :open="drawerRole" @update:open="drawerRole = $event">
    <RoleForm
      v-if="drawerRole"
      :action="roleFormAction"
      :role-selected="roleSelected"
      @onComplete="drawerRole = false"
    />
  </EndDrawer>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import RoleForm from "./forms/Role";

const store = useStore();

const drawerRole = ref(false);
const roleFormAction = ref("create");
const roleSelected = ref(null);

const getRoles = computed(() => store.getters.getRoles);

const onOpenDrawerRole = ({ action, role = null }) => {
  roleSelected.value = { ...role };
  drawerRole.value = true;
  roleFormAction.value = action;
};
</script>
